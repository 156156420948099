<template>
<div class="about-page w-full h-screen md:pt-4 md:p-0 p-2 md:pb-0 pb-6">
  <popupCharge :want-to-make-offer="activeCharge"/>
  <commercial :want-to-make-offer="activeCommercial" v-if="activeCommercial" :token="token" @oga="retourCommercial"/>
  <product :want-to-make-offer="activeProduct" v-if="activeProduct"/>
</div>
</template>

<script>
import popupCharge from '@/components/popup/popupCharge'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import commercial from '@/components/popup/comercialAndUser'
import product from '@/components/popup/popupProduct'

export default {
  name: 'index',
  components: {
    popupCharge,
    commercial,
    product
  },
  data () {
    return {
      activeCharge: true,
      code: null,
      activeCommercial: false,
      token: null,
      activeProduct: false
    }
  },
  created () {
    this.code = window.location.href.substr(window.location.href.length - 5)
    this.login()
  },
  methods: {
    retourCommercial (answer) {
      if (answer) {
        this.activeProduct = true
      }
      this.activeCommercial = false
    },

    login () {
      http.get(apiroutes.baseURL + apiroutes.loginAgence + '?code=' + this.code)
        .then(response => {
          this.activeCommercial = true
          this.token = response.data.access_token
          this.activeCharge = false
          const temps = 7 * (1000 * 3600 * 24)
          const expire = new Date().getTime() + temps
          this.$store.dispatch('saveExpireSession', expire)
          this.$store.dispatch('login', response.data.access_token)

          // this.$router.push('/home')
        })
        .catch(error => {
          this.$router.push('/')
          this.activeCharge = false
          console.log('erreur')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
.mobile{
  display: none;
}
  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
@media only screen and (max-width: 700px) {
  .about-page{
    width: 100%;
    height: 100%;
  }
  .titre{
    font-size: 28px;
    line-height: 37px;
  }
  .sous{
    font-size: 18px;
    line-height: 24px;
  }
  .num{
    font-size: 48.4571px;
    line-height: 25px;
  }
  .web{
    display: none;
  }
  .mobile{
    display: block;
    width: 100%;
  }
}
</style>
