<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          v-if="position === 1"
          class="success-container h-full p-6"
        >
          <div class="titre text-center">{{quiz}}</div>

          <div class="flex items-center mt-6">
            <div class="text-center button non mr-4 w-1/2 cursor-pointer" @click="non">NON</div>
            <div class="button continuer w-1/2" @click="yes">
              <span v-if="!activeCharge">OUI</span>
              <spinner color="#ffffff" v-if="activeCharge"/>
            </div>
          </div>
        </div>

        <div
          v-if="position === 2"
          class="success-container h-full p-6"
        >
          <div class="titre text-center">{{quiz}}</div>

          <phoneNumber @info="retourNumero" class="mt-6"/>

          <div class="mt-6 erro text-center">{{error}}</div>

          <div class="flex items-center mt-6">
            <div class="text-center button non mr-4 w-1/2 cursor-pointer" @click="retour">RETOUR</div>
            <div class="button continuer w-1/2" @click="yes">
              <span v-if="!activeCharge">CONTINUER</span>
              <spinner color="#ffffff" v-if="activeCharge"/>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import broker from '@/components/cedro/jsonWord/intermediates.json'
// import http from '@/plugins/http'
// import apiroutes from '@/router/api-routes'
import spinner from '@/components/helper/add/spinner'
import phoneNumber from '@/components/helper/form/phoneNumber'

export default {
  name: 'Success',
  components: { PopupBase, spinner, phoneNumber },

  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },

    token: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      allBroker: [],
      search: null,
      quiz: 'Êtes-vous un courtier?',
      activeCharge: false,
      position: 1,
      users: null,
      phone: null,
      error: null
    }
  },

  mounted () {
    this.users = this.parseJwt(this.token)
  },

  methods: {
    non () {
      this.$store.dispatch('saveCommercial', false)
      this.$emit('oga', true)
    },

    retour () {
      this.quiz = 'Êtes-vous un courtier?'
      this.position = 1
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      // console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },

    trackAgence () {
      for (const item in broker) {
        this.allBroker.push(broker[item].label)
      }
    },

    retourNumero (answer) {
      this.error = null
      this.phone = answer
    },

    fermer () {
      this.$store.dispatch('saveAgence', false)
      this.$emit('oga', false)
    },

    yes () {
      this.error = null
      if (this.position === 1) {
        this.quiz = 'Veillez entrer le numero de téléphone de votre agence'
        this.position = 2
      }
      if (this.position === 2) {
        if (this.users.username === this.phone) {
          this.$router.push('/home')
        } else {
          this.error = 'Ce numero de téléphone n\'est pas celui de l\'agence.'
        }
      }
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }
    .titre{
      font-size: 24px;
      color: black;
      font-weight: bold;
    }
    .svg-fill{
      fill: white;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .champ{
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 10px;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      font-family: $rubik;
      color: #909090;
      height: 70px;
    }

    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .non{
      background: #bebebe;
      border: none;
      color: black;
    }
    .erro {
      color: red;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre{
        font-size: 18px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        font-size: 16px;
      }
      .cardinal{
        width: 100%;
      }
    }
</style>
